<template>
  <v-dialog v-model="showModal" width="90%" persistent>
    <v-card class="events_procedure">
      <v-card-title class="events_procedure__header" :style="facial_event || eventInfo.event_code == '131588' ? 'background-color:#FCDBDA' : 'background-color:#f9f5e9'">
        <notice-icon :color="facial_event || eventInfo.event_code == '131588' ? '#ff786f' : '#ffbe38'" width="40" height="40"></notice-icon>
        <h4>{{ eventInfo.location_name }}</h4>
        <v-btn
          class="events_procedure__header--close_btn"
          icon
          color="#ff786f"
          @click="$emit('close-modal')"
        >
          <v-icon large>mdi-close-circle</v-icon>
        </v-btn>
        <!-- <v-btn class="events_procedure__header--close_btn" @click="$emit('close-modal')">
          Cerrar
        </v-btn> -->
      </v-card-title>

      <div class="events_procedure__content_container">
        <div class="events_procedure__event_preview">
          <div class="events_procedure__event_preview--header">
            <div>
              <p>Locación:</p>
              <b :title="eventInfo.location_name">{{ eventInfo.location_name }}</b>
            </div>

            <div>
              <p>Fecha:</p>
              <b>{{ eventInfo.event_time | moment("DD MMM YYYY") }}</b>
            </div>

            <div>
              <p>Hora:</p>
              <b>{{ eventInfo.event_time | moment("h:mm:ss a") }}</b>
            </div>
          </div>
          <div v-if="facial_event || (is_image && file_url != '')">
            <img
              :src="eventInfo.photo || file_url"
              alt="event_image"
            />
          </div>
          
          <video-player 
            v-else-if="show_event_video"
            class="events_procedure__event_preview--video_player"
            :options="video_options" />
          
          <v-btn class="monitoring__info_modal--speaker_button" depressed color="primary" v-if="facial_event"
            >Comunicación speaker</v-btn
          >
          <v-btn class="monitoring__info_modal--streaming_button" @click="checkCameras()" depressed color="primary">Ver en línea  <live-camera-icon></live-camera-icon></v-btn>
          <div v-if="event_reviewed && eventInfo.event_type != 'KNOWN FACE'" class="events_procedure__event_preview--footer">
            <div>
              <p>Responsable:</p>
              <b :title="eventInfo.reviewed_by_email">{{ eventInfo.reviewed_by_email }}</b>
            </div>

            <div>
              <p>Fecha:</p>
              <b>{{ eventInfo.review_date | moment("DD MMM YYYY") }}</b>
            </div>

            <div>
              <p>Hora:</p>
              <b>{{ eventInfo.review_date | moment("h:mm:ss a") }}</b>
            </div>
          </div>
        </div>

        <v-card class="events_procedure__event_form">
          <v-card-title style="color: #1C76D2;"
            >Actividad realizada</v-card-title
          >

          <v-card-text>
            <div class="events_procedure__event_form--checkbox_group">
              <v-checkbox
                v-for="(action, index) in event_actions_list"
                :key="index"
                :label="action"
                :value="action"
                v-model="event_procedure_data.actions_taken"
                class="events_procedure__event_form--checkbox"
                :disabled="event_reviewed"
              ></v-checkbox>
            </div>

            <textarea
              :class="event_reviewed ? 'events_procedure__event_form--textarea_reviewed' : 'events_procedure__event_form--textarea'"
              placeholder="Comentarios..."
              v-model="event_procedure_data.comments"
              :disabled="event_reviewed">
            </textarea>

            <div class="events_procedure__event_form--btn_group" v-if="eventInfo.event_state != 'REVIEWED'">
              <v-btn
                :large="!responsive_view"
                color="primary"
                :disabled="event_procedure_data.actions_taken.length > 0 || event_procedure_data.comments != '' ? false : true"
                @click="submitProcedure(1)"
                >Solucionado</v-btn
              >
            </div>
          </v-card-text>
        </v-card>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import NoticeIcon from "@/components/shared/icons/NoticeIcon.vue";
import LiveCameraIcon from "@/components/shared/icons/LiveCameraIcon.vue";
import VideoPlayer from "@/components/shared/VideoPlayer.vue";

export default {
  data() {
    return {
      event_actions_list: [
        "Verificar CCTV",
        "Llamada al cliente",
        "Llamada al guarda",
        "Envío supervisor",
        "Llamada policía",
        "Autorizado",
        "Intrusión",
        "Falsa Alarma",
        "Otro",
      ],
      event_procedure_data: {
        id: null,
        actions_taken: [],
        comments: "",
        solved: false,
      },
      event_reviewed: false,
      responsive_view: false,
      facial_event: false,
      file_url: '',
      is_image: false,
      show_event_video: false
    };
  },
  props: ["showModal", "eventInfo"],
  components: {
    "notice-icon": NoticeIcon,
    "live-camera-icon": LiveCameraIcon,
    VideoPlayer
  },
  methods: {
    submitProcedure(solved) {
      this.$emit("send-procedure", {
				event_ids: [this.eventInfo.id],
				location_id: this.eventInfo.location_id,
				review_comment: `${this.event_procedure_data.comments}|${this.event_procedure_data.actions_taken.join('|')}`,
				event_solved: solved == 1,
				user_id: this.getUserId()
			});
    },
    loadFormInfo(){
      this.event_procedure_data.id = this.eventInfo.id

      if(this.eventInfo.event_comments != null){
        this.event_procedure_data.actions_taken = this.eventInfo.event_comments.split("|").slice(1)
        this.event_procedure_data.comments = this.eventInfo.event_comments.split("|")[0]
      }
    },
    getEventMediaFiles(event_id){
      console.log('Entra a realizar la petición para obtener el archivo multimedia del evento!!!')
      // let default_url = "https://181.48.103.61/"
      try {
        this.$http.post('hikcentral/get_event_url',{
          event_id: event_id
        }).then(function(response){
          this.eventInfo.event_image = response.body.url
          console.log('Event Info image:', this.eventInfo.event_image)
          if(this.eventInfo.event_image.includes(".jpg")||
            this.eventInfo.event_image.includes(".png")||
            this.eventInfo.event_image.includes(".jpeg")) {
            this.is_image = true
            this.file_url = this.eventInfo.event_image
          }else{
            let event_image_url = this.eventInfo.event_image
            this.is_image = false
            this.file_url = event_image_url
            this.video_options.sources.push({src: event_image_url, type: 'video/mp4'})
            this.show_event_video = true
            // this.file_url = '<!DOCTYPE html>\
            // <html lang="es">\
            // <head>\
            //   <meta charset="UTF-8">\
            //   <meta name="viewport" content="width=device-width, initial-scale=1.0">\
            //   <title>Embeber Video</title>\
            // </head>\
            // <body>\
            // <video controlsList="nodownload" controls width="850" height="400px">\
            //   <source src="'+event_image_url+'" type="video/mp4">\
            //   Tu navegador no soporta la etiqueta de video.\
            // </video>\
            // </body>\
            // </html>'
          }
        }, function(response){
          console.log(response)
        })
      } catch (e) {
        console.log(e)
      }
    },
    checkCameras(){
			// let default_url = "https://181.48.103.61/"
			// window.open(default_url, "_blank");
			window.open(this.getMonitoredCompanies()[this.getSelectedCompany()].hikcentral_streaming_link, "_blank");
		}
  },
  mounted(){
    console.log("EVENT INFO", this.eventInfo)
    console.log("EVENT PROCED. DATA", this.event_procedure_data)

    this.responsive_view = innerWidth <= 1600
    this.event_reviewed = this.eventInfo.event_state == "REVIEWED"

    if(this.event_reviewed){
      this.loadFormInfo()
    }

    if(["131585", "131588", "131331", "FUSE-ALERT-SUSPEND", "FUSE-ALERT-ACTIVATE"].includes(this.eventInfo.event_code)){
      // if(this.eventInfo.event_image === "" || this.eventInfo.event_image === null){
      this.getEventMediaFiles(this.eventInfo.id)
      // }
    } else if(this.eventInfo.event_code == undefined || ["UNKNOWN", "KNOWN"].includes(this.eventInfo.event_type)){
      this.facial_event = true
    }
  }
};
</script>
