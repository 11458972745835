<template>
  <article class="speakers">
    <section class="speakers__container">
      <div
        v-if="!active_audio"
        class="speakers__close sos_alert__close"
        @click="updateShowSpeakers(false)">
        <close-icon></close-icon>
      </div>
      <h6 style="padding: 25px;">Elige o envía un mensaje personalizado para reproducir en el parlante de <b>{{getMonitoredCompanies().find(e => e.id === getSelectedCompany()).name}}</b> </h6>
      <div v-if="!active_audio">
        <div class="speakers__locations">
          <label for="selecth"><b>Locación</b></label>
          <v-select
            id="selecth"
            :items="monitoredLocations"
            item-text="name"
            item-value="id"
            label="Seleccionar locación"
            v-model="location_id"
            solo
          ></v-select>
        </div>

        <div class="speakers__options">
          <v-radio-group v-model="speaker_text">
            <v-radio
            v-for="(option, index) in default_options"
            :key="index"
            :label="option.title"
            :value="option.value"
            ></v-radio>
          </v-radio-group>
        </div>
        <!-- <div class="speakers__text_area">
          <v-textarea
          counter
          filled
          auto-grow
          name="input-2-1"
          :rules="rules"
          label="Escribe un texto (hasta 100 caracteres)"
          v-model="speaker_text"
          ></v-textarea>
        </div> -->
        <div class="speakers__buttons">
          <div
            class="speakers__buttons--default"
            @click="speaker_text = ''; location_id = null"
            v-show="speaker_text.length <= 100 && speaker_text.length > 0">
            Cancelar
          </div>
          <div
            :class="{ 'speakers__buttons--default': speaker_text.length > 100 || speaker_text.length <= 0 || location_id === null, 'speakers__buttons--active': speaker_text.length <= 100 && speaker_text.length > 0 && location_id}"
            @click="sendMessage()">
            Enviar Texto
          </div>
        </div>
      </div>
      <v-divider v-if="(speaker_text.length > 100 || speaker_text.length <= 0) && location_id"></v-divider>
      <!-- v-if="!(speaker_text.length <= 100 && speaker_text.length > 0)" -->
      <audio-recoder
        v-if="!((speaker_text.length <= 100 && speaker_text.length > 0) || location_id === null)"
        @audio-load="(event) => (audio = event)"
        @cancel-audio="(event) => (active_audio = event)"
        @start-audio="(event) => (active_audio = event)"/>
      <!-- <div class="speakers__buttons">
        <div class="speakers__buttons--default" @click="updateShowSpeakers(false)">
          Cancelar
        </div>
        <div
          :class="{ 'speakers__buttons--default': speaker_text.length > 100 || speaker_text.length <= 0, 'speakers__buttons--active': speaker_text.length <= 100 && speaker_text.length > 0 }"
          @click="sendAudio()">
          Enviar Audio
        </div>
      </div> -->
    </section>
  </article>
</template>

<script>
import CloseIcon from "@/components/shared/icons/CloseIcon.vue";
import AudioRecorder from "@/components/shared/AudioRecorder.vue";
export default {
  data(){
    return {
      rules: [v => v.length <= 100 || 'Máximo 100 characteres'],
      default_options:[
                       {title:"Se encuentra en un predio de propiedad privada. Usted no está autorizado para ingresar. Por favor, retirarse  del predio.",
                        value:'!grabacion1'},
                       {title:"¡Atención! Estamos contactando a la Policía Nacional y al personal de seguridad. Por favor evítese inconvenientes y ser sancionado.",
                        value:'!grabacion2'},
                       {title:"La Policía Nacional y el personal de seguridad se encuentra en camino, por favor desalojar la zona inmediatamente.",
                        value:'!grabacion3'},
                       {title:"¡Alerta! ¡Alerta! La policía Nacional y Nuestras unidades de seguridad ya se encuentran en camino. Por favor evítese inconvenientes y retírese del predio.",
                        value:'!grabacion4'}],
      speaker_text: '',
      audio: null,
      active_audio: false,
      location_id: null
    }
  },
  components: {
    "close-icon" : CloseIcon,
    "audio-recoder" : AudioRecorder,
  },
  computed: {
    monitoredLocations(){
      return this.getMonitoredCompanies().find(e => e.id === this.getSelectedCompany()).monitored_locations.filter(loc => loc.speaker);
    }    
  },
  watch:{
    audio(){
      if(this.audio != null){
        this.sendAudio()
      }
    }
  },
  methods:{
    sendMessage(){
      if(this.speaker_text.length <= 100 && this.speaker_text.length > 0 && this.location_id){
        console.log(this.speaker_text);
        console.log(this.getSelectedCompany());
        try {
          this.updateLoader(true)
          this.$http.post('app/company/custom_speaker_message',{
            data:this.encrypt({
              company_id: this.getSelectedCompany(),
              user_id: this.getUserId(),
              message: this.speaker_text,
              location_id: this.location_id
            }).toString()
          },{
            headers:{
              "X-Device-ID" : this.buildDeviceId(),
              // "Authorization" : 'Bearer ' + this.getUserToken(),
            }
          } )
          .then(function(response){
            console.log('SEND SPEAKER MESSAGE!!!', response)
            if(response.body.meta != undefined && response.body.meta != null){
              if(response.body.meta.authentication_token != undefined && response.body.meta.authentication_token != null){
                this.checkToken(response.body.meta.authentication_token)
              }
            }
            // alert("Mensaje enviado")
            this.updateShowSpeakers(false)
          }, function(response){
            if(response.body.meta != undefined && response.body.meta != null){
                if(response.body.meta.authentication_token != undefined && response.body.meta.authentication_token != null){
                this.checkToken(response.body.meta.authentication_token)
                }
            }
            console.log("Hubo un problema al envíar el mensaje, intenta nuevamente")
            this.updateLoader(false)
          })
        } catch (e) {
          this.updateLoader(false)
          alert(e.message)
        }
      }
    },
    sendAudio(){
      if (this.audio) {
        try {
          this.updateLoader(true)
          let formData = new FormData();
          formData.append('audio', this.audio);
          formData.append('data', this.encrypt({
            company_id: this.getSelectedCompany(),
            user_id: this.getUserId(),
            location_id: this.location_id
          }).toString());
          this.$http.post('app/company/custom_speaker_audio', formData, {
            headers: {
              "X-Device-ID": this.buildDeviceId(),
              // "Authorization" : 'Bearer ' + this.getUserToken(),
            }
          })
            .then(function (response) {
              console.log('SEND SPEAKER MESSAGE!!!', response)
              if (response.body.meta != undefined && response.body.meta != null) {
                if (response.body.meta.authentication_token != undefined && response.body.meta.authentication_token != null) {
                  this.checkToken(response.body.meta.authentication_token)
                }
              }
              alert("Mensaje enviado")
              this.updateShowSpeakers(false)
            }, function (response) {
              if (response.body.meta != undefined && response.body.meta != null) {
                if (response.body.meta.authentication_token != undefined && response.body.meta.authentication_token != null) {
                  this.checkToken(response.body.meta.authentication_token)
                }
              }
              console.log("Hubo un problema al envíar el mensaje, intenta nuevamente")
              this.updateLoader(false)
            })
        } catch (e) {
          this.updateLoader(false)
          alert(e.message)
        }

      }
    }
  }
}
</script>

<style lang="css">
</style>
