<template>
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
    width="150.000000pt" height="150.000000pt" viewBox="0 0 150.000000 150.000000"
    preserveAspectRatio="xMidYMid meet">

        <g transform="translate(0.000000,150.000000) scale(0.100000,-0.100000)" :fill="color" stroke="none">
            <path d="M450 1215 c-157 -78 -292 -150 -299 -159 -8 -9 -11 -26 -7 -38 9 -35
            74 -118 106 -136 25 -14 28 -23 33 -81 4 -55 2 -69 -16 -92 -65 -89 -2 -300
            97 -325 17 -4 29 -19 41 -50 26 -68 114 -158 193 -196 61 -30 75 -33 157 -33
            78 1 98 4 147 29 70 34 168 136 192 198 13 33 26 47 57 62 100 46 150 244 81
            319 -18 19 -20 31 -15 88 5 58 8 67 33 81 32 18 97 101 106 136 4 12 1 29 -7
            38 -15 18 -582 304 -601 303 -7 0 -141 -65 -298 -144z m543 -76 c133 -67 243
            -123 245 -124 9 -8 -22 -46 -48 -60 -43 -22 -837 -22 -880 0 -26 14 -57 52
            -48 60 3 3 481 243 486 244 1 1 111 -54 245 -120z m130 -312 c8 -22 -17 -80
            -46 -101 -27 -20 -43 -21 -305 -24 -180 -2 -290 1 -314 8 -46 13 -78 52 -78
            96 l0 34 369 0 c288 0 370 -3 374 -13z m-373 -212 c343 0 346 0 374 22 34 28
            46 22 46 -21 0 -73 -46 -152 -83 -140 -25 8 -54 -20 -62 -60 -16 -75 -99 -172
            -174 -203 -55 -24 -157 -21 -211 7 -64 32 -131 106 -154 171 -24 68 -44 92
            -72 85 -29 -8 -60 27 -75 85 -7 25 -9 57 -5 73 l7 27 32 -23 c32 -23 33 -23
            377 -23z"/>
        </g>
    </svg>
</template>
  
<script>
    export default {
        props:{
            color:{
                default: '#c1272d',
                type: String
            }
        }
    }
</script>