<template>
  <v-navigation-drawer
    v-model="drawer"
    fixed
    clipped
    class="navigation__container"
    app>
    <v-list-item class="navigation__container--header">
      <v-list-item-content>
        <v-list-item-title class="text-h4 clients_head">
          <clients-icon></clients-icon> <p>Clientes</p>
        </v-list-item-title>
        <!-- <v-badge v-if="monitored_company.pending_events > 0" color="red" :content="monitored_company.pending_events">{{ monitored_company.name }}</v-badge> -->
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <v-list class="navigation__container--drawer">
      <v-list-item
        @click="changeRoute(monitored_company.id, monitored_company)"
        v-for="monitored_company in getMonitoredCompanies()"
        :key="monitored_company.id"
        class="navigation__container--item"
        :class="[monitored_company.pending_events > 0 ? 'alert' : 'normal',
                 monitored_company.id === getSelectedCompany() && monitored_company.pending_events > 0 ? 'selected_alert': monitored_company.id === getSelectedCompany() && monitored_company.pending_events === 0 ? 'selected' :'',
                 findCompanyInEvents(monitored_company.id) ? 'new_event_row' : '']">
        <v-list-item-content>
          <!-- <v-list-item-title class="navigation__container--item_text_container" v-if="monitored_company.pending_events > 0">
            <v-badge color="red" :content="monitored_company.pending_events">{{ monitored_company.name }}</v-badge>
          </v-list-item-title> -->
          <v-list-item-title :title="monitored_company.name">
            <v-row no-gutters class="navigation__container--item_content">
              <v-col
                class="navigation__container--item_title"
                :class="{'new_event_color': findCompanyInEvents(monitored_company.id)}">
                {{ monitored_company.name }}
              </v-col>
              <v-col cols="auto">
                <arrow-circle-right-icon
                  width="30"
                  height="30"
                  v-if="monitored_company.id === getSelectedCompany()"
                  :color="monitored_company.pending_events > 0 ? '#606e93' : ''">
                </arrow-circle-right-icon>
                <!-- <event-dot
                  width="25"
                  height="25"
                  v-if="monitored_company.id === getSelectedCompany()"></event-dot> -->
                <circle-icon
                  width="30"
                  height="30"
                  v-else-if="monitored_company.pending_events > 0 && !findCompanyInEvents(monitored_company.id)"
                  :class="{'new_event': findCompanyInEvents(monitored_company.id)}">
                </circle-icon>
              </v-col>
            </v-row>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <!-- <div
      class="drawer__close_section"
      @click="closeSession()">
      Cerrar Sesión
    </div> -->
  </v-navigation-drawer>
</template>

<script>
import CircleIcon from "@/components/shared/icons/CircleIcon.vue";
import ArrowCircleRightIcon from "@/components/shared/icons/ArrowCircleRightIcon.vue";
  export default {
    data: () => ({
      drawer: null,
      selected_item:'',
      selected_subitem:'',
      current_name: 'Compañías Monitoreadas',
      items: [
        {
          title: 'Compañías Monitoreadas',
          divider: true,
          principal_route: '',
          section: 'companys',
          id: 0
        },
      ]
    }),
    components: {
      "circle-icon" : CircleIcon,
      "arrow-circle-right-icon" : ArrowCircleRightIcon
    },
    watch: {
      selected_item: function() {
        if(this.selected_item==='classnav'){
          this.current_name = 'Clases';
        }
      }
    },
    mounted(){
      this.subscribeToMonitorChannel()
    },
    methods:{
      findCompanyInEvents(company){
        var vm = this;
        return (vm.getCurrentEvents().filter(event => event.company == company).length > 0)
      },
      changeRoute(index, company){
        if (this.$route.params.id !== company.id){
          this.updateSelectedCompany(index)
          this.validateSections(company)
          this.updateShowCompanySections(false)
          this.$router.push({name: 'monitoring', params: {id: company.id}})
        }
      },
      subscribeToMonitorChannel() {
        this.unsubscribeFromMonitorChannel()
        console.log('Entra a suscribirse al canal del Monitor!!!', this.getUserId())
        // Suscribirse al canal del monitor
        this.$cable.subscribe({
          channel: "EventsMonitoringChannel",
          room: `room_events_monitoring_${this.getUserId()}`,
          monitor_id: this.getUserId(),
        });
      },
      unsubscribeFromMonitorChannel() {
        this.$cable.unsubscribe("EventsMonitoringChannel")
      }
    }
  }
</script>
