<template>
  <article
    class="company_sections">
    <section
      :key="index"
      v-for="(section, index) in company_sections"
      class="company_sections__container">
      <div
        class="company_sections__section"
        v-if="section.title != getCurrentSection()"
        @click="renderSection(section)">
        <component v-bind:is="section.icon"></component>
        <p>{{section.title}}</p>
      </div>
    </section>
  </article>
</template>

<script>
export default {
  data(){
    return{
      company_sections:[],
      load_sections: false
    }
  },
  mounted(){
    this.filterSections()
  },
  methods:{
    filterSections(){
      var vm = this
      vm.load_sections = false
      console.log(vm.getCompanySections());
      var current_name = vm.getCurrentSection().title
      vm.company_sections = vm.getCompanySections().filter((section) => section.title != current_name || section.title != "Abrir Puerta")
      console.log("Sections!!!!!!");
      console.log(vm.company_sections);
      vm.load_sections = true
    },
    renderSection(section){
      if(section.title == "Parlantes"){
        this.updateShowCompanySections(false)
        this.updateShowSpeakers(true)
      } else if(section.title == "Invitaciones"){
        this.updateShowCompanySections(false)
        this.updateShowInvitationsForm(true)
      // } else if(section.title == "Abrir Puerta"){
      //   this.updateShowCompanySections(false)
      //   this.updateShowOpenDoors(true)
      }else {
        this.updateShowCompanySections(false)
        // this.updateCurrentSection(section)
      }
    }
  }
}
</script>

<style lang="css">
</style>
