<template>
    <svg :fill="color" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" xml:space="preserve">
        <path id="fire--alarm_1_" d="M5.846,26.154C3.134,23.442,1.64,19.836,1.64,16S3.134,8.558,5.846,5.846l0.509,0.509
            C3.779,8.931,2.36,12.356,2.36,16s1.419,7.069,3.995,9.645L5.846,26.154z M26.154,26.154l-0.51-0.51
            c2.576-2.576,3.995-6.001,3.995-9.645c0-3.643-1.419-7.068-3.995-9.645l0.51-0.509C28.866,8.558,30.36,12.165,30.36,16
            C30.36,19.836,28.866,23.441,26.154,26.154z M16,24.36c-4.61,0-8.36-3.751-8.36-8.36c0-4.61,3.75-8.36,8.36-8.36
            c4.609,0,8.36,3.75,8.36,8.36C24.36,20.609,20.609,24.36,16,24.36z M16,8.36c-4.213,0-7.64,3.427-7.64,7.64s3.427,7.64,7.64,7.64
            s7.64-3.427,7.64-7.64S20.213,8.36,16,8.36z M24.033,24.033l-0.51-0.51c2.01-2.009,3.116-4.682,3.116-7.523
            s-1.106-5.514-3.116-7.524l0.51-0.509c2.146,2.146,3.327,4.999,3.327,8.033S26.179,21.888,24.033,24.033z M7.967,24.033
            C5.822,21.888,4.64,19.034,4.64,16s1.182-5.887,3.327-8.033l0.509,0.509C6.467,10.486,5.36,13.158,5.36,16s1.107,5.515,3.116,7.523
            L7.967,24.033z M16,17.36c-0.75,0-1.36-0.61-1.36-1.36s0.61-1.36,1.36-1.36s1.36,0.61,1.36,1.36S16.75,17.36,16,17.36z M16,15.36
            c-0.353,0-0.64,0.287-0.64,0.64s0.287,0.64,0.64,0.64s0.64-0.287,0.64-0.64S16.353,15.36,16,15.36z"/>
        <rect id="_Transparent_Rectangle" style="fill:none;" width="32" height="32"/>
    </svg> 
</template>
  
<script>
    export default {
        props:{
            color:{
                default: '#c1272d',
                type: String
            }
        }
    }
</script>