<template>
  <article class="sos_alert">
    <section class="sos_alert__container">
      <div @click="closeSOSModal()" class="sos_alert__close">
        <close-icon ></close-icon>
      </div>
      <div class="sos_alert__content">      
        <new-alert-icon width="100" v-if="speaker" height="100"></new-alert-icon>
        <panic-icon :color="'#8eaadb'" v-else width="100" height="100"></panic-icon>
        <p v-html="user_data"></p>
      </div>
    </section>
  </article>
</template>

<script>
import CloseIcon from "@/components/shared/icons/CloseIcon.vue";
export default {
  props:{
    user_data:{
      type: String,
      default: ''
    },
    speaker:{
      type: Boolean,
      default: false
    }
  },
  components: {
    "close-icon" : CloseIcon,
    // "alert-icon" : AlertIcon,
  },
  methods:{
    closeSOSModal(){
      this.updateSOSAlert(false)
    }
  }
}
</script>
